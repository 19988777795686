<template>
  <h1 class="content-title"> {{ $t('voiceRecognition') }} </h1>
  <div class="mb-4 mt-4">
    <p>
      La reconnaissance vocale est une discipline qui découle du croisement entre l’intelligence artificielle, la
      linguistique et le traitement de signal. Elle désigne un large éventail de techniques se rapportant généralement
      au traitement du signal acoustique, pour en extraire de la connaissance et de la sémantique.
    </p>
    <p>
      Cette discipline couvre un important domaine d’applications incluant entre autres la reconnaissance vocale, la
      transcription automatisée à partir de la voix, la traduction automatisée de la parole et la reconnaissance
      contextuelle à partir du fond sonore qui nous entoure.
    </p>
    <p>
      La reconnaissance vocale permet aux ordinateurs de traiter le signal acoustique (considéré comme une donnée non
      structurée), de le transformer en une donnée numérique, puis de le structurer pour en extraire la sémantique afin
      d’actionner un ensemble de tâches.
    </p>
    <p>
      Les données acoustiques sont diverses et variées : paroles, sons, bruits, etc. Pour en comprendre le sens, il est
      important d’exécuter diverses étapes toutes liées au traitement du signal sonore et à la transformation des
      données formatées de sorte qu’elles soient lisibles par les ordinateurs.
    </p>
    <p>
      Les technologies de reconnaissance vocale sont de plus en plus utilisées par différentes industries et même par
      le consommateur. Pour les entreprises, les services et les interfaces de programmation les plus utilisés en
      matière de reconnaissance vocale sont :
    </p>
    <ul>
      <li>
        <a href="https://cloud.google.com/speech-to-text" target="_blank">Speech API;</a>
      </li>
      <li>
        <a href="https://developer.ibm.com/components/watson-apis/" target="_blank">WATSON API (IBM);</a>
      </li>
      <li>
        <a href="https://azure.microsoft.com/fr-ca/services/cognitive-services/?&ef_id=CjwKCAjwn8SLBhAyEiwAHNTJbTSMEo4MW
        W_58au6g4-nc39mfDTIuBVYzMzo4EcOMG5gL97N4gTRIBoCda0QAvD_BwE:G:s&OCID=AID2200157_SEM_CjwKCAjwn8SLBhAyEiwAHNTJbTSME
        o4MWW_58au6g4-nc39mfDTIuBVYzMzo4EcOMG5gL97N4gTRIBoCda0QAvD_BwE:G:s&gclid=CjwKCAjwn8SLBhAyEiwAHNTJbTSMEo4MWW_58au
        6g4-nc39mfDTIuBVYzMzo4EcOMG5gL97N4gTRIBoCda0QAvD_BwE" target="_blank">Cognitive Services (Microsoft).</a>
      </li>
    </ul>
    <p>
      Voici quelques technologies destinées au consommateur :
    </p>
    <ul>
      <li>
        <a href="https://assistant.google.com/intl/fr_ca/" target="_blank">Google Assistant;</a>
      </li>
      <li>
        <a href="https://www.apple.com/ca/fr/siri/" target="_blank">Siri (Apple);</a>
      </li>
      <li>
        <a href="https://www.amazon.ca/-/fr/gp/browse.html?node=21497226011&ref_=nav_em__ods_ha_con_help_0_2_2_16" target="_blank">
          Alexa (Amazon).</a>
      </li>
    </ul>
    <p>
      La performance de la reconnaissance vocale dépend de plusieurs facteurs : la qualité sonore de l’enregistrement,
      les données d’entraînement utilisées pour la modélisation acoustique, le bruit environnant, etc. Un programme de
      reconnaissance vocale performant peut :
    </p>
    <ul>
      <li>
        Filtrer correctement les bruits présents lors de l’enregistrement acoustique en entrée;
      </li>
      <li>
        Reconnaître avec exactitude la narration dans un enregistrement audio, indépendamment de la voix, du ton, de
        l’intonation, du rythme ou du dialecte de l’interlocuteur;
      </li>
      <li>
        Reconnaître le sens des requêtes exprimées par l’utilisateur peu importe la complexité de la formulation.
      </li>
    </ul>
  </div>
  <div>
    <tts/>
    <stt/>
  </div>

</template>

<script>
import tts from "@/components/Examples/tts";
import stt from "@/components/Examples/stt";

export default {
  name: "VoiceRecognition",
  components: {
    tts,
    stt
  }
}
</script>

<style scoped>

</style>