<template>
  <h1 class="content-title">{{ $t('machineLearning') }}</h1>
  <div class="mb-4 mt-4">
    <p>
      L’apprentissage machine (machine learning) ou apprentissage automatique est un sous-domaine de l’intelligence
      artificielle. Cela fait référence à un ensemble de techniques mathématiques et de transformation de données
      permettant à un logiciel d’apprendre à partir de données historiques afin de s’ajuster automatiquement pour
      parfaire l’exécution d’une tâche spécifique.
    </p>
    <p>
      L’apprentissage machine permet aux ordinateurs d’apprendre à partir d’observations passées (contextuellement
      appelées « données historiques ») afin d’identifier automatiquement les règles à appliquer pour la résolution d’un
      problème donné. La démocratisation des techniques d’apprentissage machine et la disponibilité des données
      nécessaires permettent d’identifier de nombreux cas d’applications, tels :
    </p>
    <ul>
      <li>
        la reconnaissance d’images;
      </li>
      <li>
        le traitement du langage naturel;
      </li>
      <li>
        la détection d’anomalies;
      </li>
      <li>
        les séries temporelles;
      </li>
    </ul>
    <p>
      Il existe une multitude de librairies, de plateformes et d’outils disponibles permettant l’utilisation et la
      création d’algorithmes d’apprentissage machine :
    </p>
    <ul>
      <li>
        Librairies d’apprentissage machine :
        <a href="https://www.python.org/" target="_blank">Python,</a> <a href="https://golang.org/" target="_blank">Go,</a> <a href="https://www.tensorflow.org/?hl=fr" target="_blank">Tensorflow,</a> <a href="https://pytorch.org/" target="_blank">PyTorch,</a> etc.;
      </li>
      <li>
        Plateforme d’apprentissage machine: <a href="https://cloud.google.com/products/ai" target="_blank">Google,</a> <a href="https://aws.amazon.com/fr/machine-learning/" target="_blank">Amazon,</a> <a href="https://azure.microsoft.com/fr-ca/services/machine-learning/" target="_blank">Microsoft,</a> <a href="https://www.ibm.com/cloud/learn/machine-learning" target="_blank">IBM</a> ainsi que la majorité des grandes
        organisations technologiques offrent aujourd’hui des plateformes en ligne permettant l’exploitation de
        différents services en apprentissage machine;
      </li>
      <li>
        Outils d’apprentissage machine:
        <a href="https://www.rossintelligence.com/what-is-ai" target="_blank">ROSS Intelligence,</a> <a href="https://www.mindbridge.ai/" target="_blank">MindBridge,</a> <a href="https://blindspot.ai/products/macula/" target="_blank">Macula AI,</a> <a href="https://www.coveo.com/fr/ressources/videos/coveo-machine-learning-explained" target="_blank">COVEO,</a> etc.
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MachineLearning"
}
</script>

<style scoped>

</style>