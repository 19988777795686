<template>
  <h1 class="content-title"> {{ $t('bigData') }} </h1>
  <div class="mb-4 mt-4">
    <p>
      Le Big Data, aussi appelé données volumineuses, méga données ou données massives, réfère à des données qui
      nécessitent des outils de traitement et des technologies analytiques adaptés à leur taille, leur volume, leur
      variété et leur vélocité élevés.
    </p>
    <p>
      Le réseau Internet, le faible coût des ordinateurs, la popularisation des appareils mobiles et les réseaux sociaux
      ont contribué à la croissance phénoménale du volume de données générées quotidiennement. La taille des données
      nécessitant un stockage et un traitement croît à un rythme plus exponentiel que linéaire. Au volume croissant,
      s’ajoute la variété des données mises en ligne chaque jour : vidéos, images, sons, etc. Le contenu généré prend
      des formes diverses nécessitant des outils de gestion de données et des approches analytiques capables de traiter
      des données non structurées.
    </p>
    <p>
      La croissance fulgurante de l’activité numérique et la complexité grandissante de la nature des données ont exigé
      une nouvelle génération d’outils de gestion de données appelés outils de traitement de données volumineuses.
    </p>
    <p>
      Les technologies de traitement des données volumineuses sont à la base de la plupart des applications modernes
      utilisées quotidiennement. Des réseaux sociaux aux technologies de diffusion de contenu et jusqu’aux outils
      d’exploration des données génétiques, les technologies de Big Data ont ouvert la porte à de nouvelles possibilités
      pour des secteurs différents et diverses raisons : elles sont utiles pour extraire, transformer, traiter et
      stocker des données volumineuses pouvant avoir des formats structurés, semi-structurés ou non structurés.
    </p>
    <p>
      Trois dimensions (dites les 3V) permettent souvent de qualifier les données de volumineuses :
    </p>
    <ul>
      <li>
        le volume de données, qui nécessitent plusieurs ordinateurs ou des super ordinateurs pour être traitées
        efficacement;
      </li>
      <li>
        la variété de données, qui sont de formats différents dont la forme varie du structuré au non structuré;
      </li>
      <li>
        la vélocité des données, qui réfère à la vitesse à laquelle les données sont générées et, par conséquent,
        collectées, traitées, stockées et exploitées.
      </li>
    </ul>
    <p>
      Sans surprises, la majorité des grands fournisseurs de services infonuagiques offrent des solutions de gestion de
      données volumineuses. Amazon, Google, Microsoft, IBM, Alibaba et bien d’autres joueurs proposent des services
      permettant de couvrir l’ensemble du spectre du Big Data, à savoir :
    </p>
    <ul>
      <li>
        la collecte de données volumineuses, avec des outils tels <a href="https://aws.amazon.com/fr/kinesis/data-firehose/?kinesis-blogs.sort-by=item.additionalFields.createdDate&kinesis-blogs.sort-order=desc" target="_blank">Amazon Kinesis Firehose,</a> <a href="https://kafka.apache.org/" target="_blank">Apache Kafka</a> et <a href="https://cloud.google.com/dataflow" target="_blank">Google Cloud Dataflow;</a>
      </li>
      <li>
        le stockage, si ces données sont en diffusion continue (Streaming data), comme <a href="https://aws.amazon.com/fr/kinesis/data-streams/" target="_blank">Amazon Kinesis Data Stream</a> et <a href="https://azure.microsoft.com/fr-ca/services/stream-analytics/?&ef_id=CjwKCAjwn8SLBhAyEiwAHNTJbb87hs70y2howpd7lK9X1gEzMVr5zfPXp_eRLs2mZnam3hQ_pOsV4BoCxPQQAvD_BwE:G:s&OCID=AID2200157_SEM_CjwKCAjwn8SLBhAyEiwAHNTJbb87hs70y2howpd7lK9X1gEzMVr5zfPXp_eRLs2mZnam3hQ_pOsV4BoCxPQQAvD_BwE:G:s&gclid=CjwKCAjwn8SLBhAyEiwAHNTJbb87hs70y2howpd7lK9X1gEzMVr5zfPXp_eRLs2mZnam3hQ_pOsV4BoCxPQQAvD_BwE#overview" target="_blank">Microsoft Azure Stream Analytics,</a> ou si ce sont des données stockées, comme <a href="https://aws.amazon.com/fr/s3/" target="_blank">Amazon S3</a> et <a href="https://azure.microsoft.com/fr-ca/services/storage/blobs/?&ef_id=CjwKCAjwn8SLBhAyEiwAHNTJbXIIsfw_Iqa6dAY-60blwg5HewHsxV-lqi_1nJ2O4GNMoi8usx64ihoCv2AQAvD_BwE:G:s&OCID=AID2200157_SEM_CjwKCAjwn8SLBhAyEiwAHNTJbXIIsfw_Iqa6dAY-60blwg5HewHsxV-lqi_1nJ2O4GNMoi8usx64ihoCv2AQAvD_BwE:G:s&gclid=CjwKCAjwn8SLBhAyEiwAHNTJbXIIsfw_Iqa6dAY-60blwg5HewHsxV-lqi_1nJ2O4GNMoi8usx64ihoCv2AQAvD_BwE" target="_blank">Microsoft Azure Blob;</a>
      </li>
      <li>
        le traitement de données volumineuses : les hébergeurs infonuagiques sont souvent compatibles avec la plupart
        des technologies, comme <a href="https://hadoop.apache.org/" target="_blank">Apache Hadoop</a> et <a href="https://databricks.com/fr/spark/about" target="_blank">Apache Spark;</a>
      </li>
      <li>
        l’analyse de données volumineuses : la plupart des fournisseurs infonuagiques offrent un large éventail d’outils
        analytiques et d’apprentissage machine permettant l’extraction du contenu à partir des données volumineuses,
        comme <a href="https://aws.amazon.com/fr/sagemaker/" target="_blank">Amazon SageMaker,</a> <a href="https://studio.azureml.net/" target="_blank">Microsoft Azure Machine Learning Studio</a> et <a href="https://research.google/teams/cloud-ai/" target="_blank">Google Cloud AI;</a>
      </li>
      <li>
        la consommation des résultats, sous forme d’interface de programmation (API) ou d’outil de visualisation de
        données, comme <a href="https://www.tableau.com/fr-fr" target="_blank">Tableau Software,</a> <a href="https://powerbi.microsoft.com/fr-be/" target="_blank">Microsoft Power BI</a> et <a href="https://www.qlik.com/us/products/qlikview" target="_blank">QlikView.</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BigData"
}
</script>

<style scoped>

</style>