<template>
  <h1 class="content-title"> {{ $t('internetOfThings') }} </h1>
  <div class="mb-4 mt-4">
    <p>
      L’internet des objets (ido ou IoT – Internet of Things) est une désignation conceptuelle qui réfère à un réseau
      d’objets physiques équipés de capteurs et de transmetteurs capables de :
    </p>
    <ul>
      <li>
        se connecter sur le réseau Internet;
      </li>
      <li>
        renseigner le réseau sur leur état de fonctionnement;
      </li>
      <li>
        recevoir, traiter, transmettre et diffuser des données vers d’autres objets connectés;
      </li>
      <li>
        interopérer entre eux suivant une logique d’organisation;
      </li>
      <li>
        adapter leurs fonctionnements et comportements selon le contexte dans lequel ils évoluent.
      </li>
    </ul>
    <p>
      L’Internet des objets est une intersection entre plusieurs disciplines telles que les systèmes embarqués, la
      gestion de réseaux, la télédétection, la science de données, l’analytique et l’apprentissage machine. L’évolution
      de cet ensemble de disciplines a largement contribué à l’émergence et à la popularisation des objets intelligents
      (smart devices) dont la disponibilité a contribué à son tour à l’accélération de l’adoption des technologies de
      l’industrie 4.0 et des fondements de la ville intelligente.
    </p>
    <p>
      L’Internet des objets est souvent associé au principe de l’informatique omniprésente où une fois les objets d’un environnement
      connectés, il se transforme en un espace intelligent qui s’adapte automatiquement aux différents besoins de ses
      occupants.
    </p>
    <p>
      Cette technologie est devenue une étape primordiale pour les industries manufacturières, la logistique, la gestion
      des villes intelligentes et le consommateur. Plusieurs facteurs ont favorisé la disponibilité de cette technologie
      :
    </p>
    <ul>
      <li>
        <strong>La décroissance des coûts liés à la fabrication de l’électronique :</strong> la popularité des produits
        électroniques auprès des consommateurs a largement servi à la décroissance des coûts de fabrication alors que la
        production en masse des composantes électroniques avait reformulé les modèles économiques de la discipline et
        les méthodes de fabrication;
      </li>
      <li>
        <strong>L’accessibilité du réseau Internet :</strong> l’accessibilité du réseau Internet sous toute ses formes (points
        d’accès, coûts, etc.) a fortement contribué à l’émergence de l’Internet des objets; la connexion permanente n’a
        plus de coûts conséquents par rapport à la valeur qu’elle offre aux organisations et aux consommateurs;
      </li>
      <li>
        <strong>La montée fulgurante de la puissance de calcul :</strong> la puissance des processeurs ayant connu une croissance
        quasi - exponentielle a favorisé l’émergence d’objets connectés et intelligents munis de capacité de calcul
        autonome leur permettant d’interagir indépendamment d’un serveur central.
      </li>
    </ul>
    <p>
      L’Internet des objets est mis à profit à travers différents niveaux d’utilisation, comme : la domotique,
      l’industrie 4.0 et la ville intelligente.
    </p>
    <p>
      Suivant la vague de l’Internet des objets, la plupart des fournisseurs de services infonuagiques ont mis en place
      des offres de plus en plus complètes dédiées aux besoins des systèmes IoT. Leurs utilisateurs peuvent créer,
      déployer, gérer et optimiser un réseau d’objets connectés sans expertise confirmée en réseau ou en électronique
      embarquée. En effet, la plupart des fournisseurs offrent la possibilité d’installer un logiciel pilote sur les
      objets afin de les connecter avec leurs services infonuagiques et de les gérer avec leurs plateformes.
    </p>
    <p>
      L’utilisation des plateformes IoT fournissent donc plusieurs avantages. L’utilisateur peut déployer rapidement, à
      des coûts raisonnables, un système IoT d’une complexité relativement élevée. Cependant, le risque existe de
      développer une dépendance à ces plateformes. En effet, en abritant les données des objets connectés sur un service
      infonuagique, la migration vers une autre plateforme devient coûteuse et difficile à réaliser.
    </p>
    <p>
      Voici les plateformes infonuagiques les plus populaires en IoT :
    </p>
    <ul>
      <li>
        <a href="https://aws.amazon.com/fr/iot/" target="_blank">Amazon Web Services IoT;</a>
      </li>
      <li>
        <a href="https://cloud.google.com/solutions/iot" target="_blank">Google Cloud IoT;</a>
      </li>
      <li>
        <a href="https://www.ibm.com/fr-fr/cloud/internet-of-things" target="_blank">IBM Watson IoT;</a>
      </li>
      <li>
        <a href="https://azure.microsoft.com/fr-ca/overview/iot/" target="_blank">Microsoft Azure IoT.</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "InternetOfThings"
}
</script>

<style scoped>

</style>