<template>
  <h1 class="content-title"> {{ $t('naturalLanguageProcessing') }} </h1>
  <div class="mb-4 mt-4">
    <p>
      Le traitement du langage naturel est un sous-domaine de l’intelligence artificielle et de la linguistique se
      rapportant au traitement informatique des données textuelles pour permettre l’extraction automatisée et la
      compréhension de la sémantique qui y est enfouie.
    </p>
    <p>
      Ce sous-domaine englobe l’ensemble des techniques de traitement du langage naturel par ordinateur qui servent
      généralement à diverses opérations appliquées sur le contenu textuel à partir d’un texte donné ( classification
      automatisée des documents, résumé des textes descriptifs, extraction des mots-clés ou des entités, etc.).
    </p>
    <p>
      Les programmes de traitement du langage naturel permettront aux ordinateurs de traiter les données qui se
      présentent sous la forme textuelle, d’en extraire le sens et, ainsi, d’actionner un ensemble de tâches en
      conséquence. Une bonne partie des données produites par les humains se présentent sous forme d’un texte destiné à
      véhiculer du contenu compréhensible par d’autres humains. Qu’il s’agisse de documents de travail, de formulaires,
      et même d’interactions sociales, le format textuel diffuse une bonne partie des connaissances et orchestre la
      majorité de nos interactions.
    </p>
    <p>
      Les programmes de traitement du langage naturel sont donc largement déployés dans les différents secteurs et cas
      d’usage. Ils couvrent un large éventail d’applications dont les plus connues sont les suivantes :
    </p>
    <ul>
      <li>
        les agents conversationnels, comme
        <a href="https://assistant.google.com/intl/fr_ca/" target="_blank">Google Assistant,</a> <a href="https://www.apple.com/ca/fr/siri/" target="_blank">Siri (Apple)</a> et <a href="https://www.amazon.ca/-/fr/gp/browse.html?node=21497226011&ref_=nav_em__ods_ha_con_help_0_2_2_16" target="_blank">Alexa (Amazon);</a>
      </li>
      <li>
        la fouille de données textuelles, comme le fait les moteurs de recherche tels Chrome (Google), Bing (Microsoft)
        et Safari (Apple);
      </li>
      <li>
        l’analyse de sentiments;
      </li>
      <li>
        l’apprentissage machine;
      </li>
      <li>
        la gestion documentaire.
      </li>
    </ul>
    <p>
      Il existe une multitude de librairies, de plateformes et d’outils de traitement du langage naturel, tels :
    </p>
    <ul>
      <li>
        les librairies comme
        <a href="https://spacy.io/">spaCY,</a> <a href="https://www.nltk.org/">NLTK</a> et <a href="https://pypi.org/project/gensim/">Gensim;</a>
      </li>
      <li>
        les plateformes comme
        <a href="https://www.ibm.com/fr-fr/watson">Watson (IBM)</a> et <a href="https://cloud.google.com/dialogflow/docs">Dialogflow;</a>
      </li>
      <li>
        les outils de traitement comme
        <a href="https://www.rossintelligence.com/what-is-ai">Ross Intelligence,</a> <a href="https://www.keatext.ai/fr/">Keatext,</a> <a href="https://www.grammarly.com/">Grammarly,</a> <a href="https://www.moveworks.com/">Moveworks</a> et <a href="https://www.fathomhq.com/">Fathom.</a>
      </li>
    </ul>
  </div>
  <div>
    <entity-and-sentiment-analysis/>
    <question-answering/>
    <summarization/>
    <language-translation/>
  </div>
</template>

<script>
import entityAndSentimentAnalysis from "@/components/Examples/entityAndSentimentAnalysis";
import questionAnswering from "@/components/Examples/questionAnswering";
import summarization from "@/components/Examples/summarization";
import languageTranslation from "@/components/Examples/languageTranslation";

export default {
  name: "NaturalLanguageProcessing",
  components: {
    entityAndSentimentAnalysis,
    questionAnswering,
    summarization,
    languageTranslation
  }
}
</script>

<style scoped>

</style>