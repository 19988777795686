import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Guide from '../views/Guide.vue'
import Tools from "../views/Tools.vue";
import BigData from "../views/content/BigData";
import DataAnalysis from "../views/content/DataAnalysis";
import DataGeneration from "../views/content/DataGeneration"
import DeepLearning from "../views/content/DeepLearning";
import ImageRecognition from "../views/content/ImageRecognition";
import InternetOfThings from "../views/content/InternetOfThings";
import MachineLearning from "../views/content/MachineLearning";
import NaturalLanguageProcessing from "../views/content/NaturalLanguageProcessing";
import NeuralNetwork from "../views/content/NeuralNetwork";
import VoiceRecognition from "../views/content/VoiceRecognition";

import ParentPage from '../views/content/ParentPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/guide',
    name: 'guide',
    component: Guide
  },
  {
    path: '/tools',
    name: 'tools',
    component: Tools
  },
  {
    path: '/',
    name: 'parentPage',
    component: ParentPage,
    children: [
      {
        path: 'machineLearning',
        name: 'machineLearning',
        component: MachineLearning
      },
      {
        path: 'imageRecognition',
        name: 'imageRecognition',
        component: ImageRecognition
      },
      {
        path: 'voiceRecognition',
        name: 'voiceRecognition',
        component: VoiceRecognition
      },
      {
        path: 'naturalLanguageProcessing',
        name: 'naturalLanguageProcessing',
        component: NaturalLanguageProcessing
      },
      {
        path: 'deepLearning',
        name: 'deepLearning',
        component: DeepLearning
      },
      {
        path: 'bigData',
        name: 'bigData',
        component: BigData
      },
      {
        path: 'internetOfThings',
        name: 'internetOfThings',
        component: InternetOfThings
      },
      {
        path: 'dataAnalysis',
        name: 'dataAnalysis',
        component: DataAnalysis
      },
      {
        path: 'dataGeneration',
        name: 'dataGeneration',
        component: DataGeneration
      },
      {
        path: 'neuralNetwork',
        name: 'neuralNetwork',
        component: NeuralNetwork
      }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach(to => {
  localStorage.setItem("route", to.path);
});

export default router
